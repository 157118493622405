@font-face {
  font-family: AvenirLight;
  src: url(/Fonts/avenir-light.ttf);
}
@font-face {
  font-family: AvenirRoman;
  src: url(/Fonts/avenir-roman.ttf);
}
@font-face {
  font-family: AvenirBook;
  src: url(/Fonts/avenir-book.ttf);
}
@font-face {
  font-family: AvenirMedium;
  src: url(/Fonts/avenir-medium.ttf);
}
@font-face {
  font-family: AvenirHeavy;
  src: url(/Fonts/avenir-heavy.ttf);
}

:root {
  /* Fonts */
  --headingFont: 'AvenirHeavy', sans-serif;
  --bodyFont: 'AvenirBook', sans-serif;
  --bodyMediumFont: 'AvenirMedium', sans-serif;
  --bodyLightFont: 'AvenirLight', sans-serif;
  --subHeadingFont: 'AvenirRoman', sans-serif;

  /* Light */
  /* --light: #fff;
  --dark: #0702a3;
  --primary: #8e65b3;
  --secondary: #f0a035;
  --accent: #8685ef;
  --headerFooterBg: #f0a035;
  --tShirtColor: var(--primary);
  --tShirtCollarColor: var(--primary); */

  /* Dark */
  --light: #06020e;
  --dark: #fcf7ff;
  --primary: #06020e;
  --secondary: #c9c6cf;
  --accent: #9781ea;
  --headerFooterBg: var(--light);
  /* --tShirtColor: var(--primary);
  --tShirtCollarColor: var(--primary); */
  --tShirtColor: var(--secondary);
  --tShirtCollarColor: var(--secondary);
  --light-darker: #38353e;
  --dark--lighter: #b0adb3;
}
html {
  /* font-size: 100%; */
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  /* font-size: 16px; */
}

nav {
  grid-area: nav;
}
header {
  grid-area: header;
}
#portrait {
  grid-area: portrait;
}
main {
  grid-area: main;
}
footer {
  grid-area: footer;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100vw;
  height: fit-content;
  overflow-x: hidden; /* Prevent horizontal scrolling */
  /* background-color: #ffffff; */
  background-color: var(--light);
  padding: 0 0 0 0;
  margin: 0;
  display: grid;
  grid-template-columns:
    minmax(100px, 1fr) minmax(100px, 1fr) minmax(100px, 1fr)
    minmax(100px, 1fr);
  grid-template-rows: 7vh 93vh minmax(100px, 5fr) 7rem;
  grid-template-areas:
    'nav nav nav nav'
    'header header portrait portrait'
    'main main main main'
    'footer footer footer footer';
  grid-gap: 1rem;
}

nav {
  display: flex;
  justify-content: space-between;
  align-content: center;
  background-color: var(--headerFooterBg);
  /* padding: 1em 0; */
  height: 100%;
}
nav > svg {
  margin: auto 2rem;
}

nav ul {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  list-style: none;
  padding: 0;
  margin: 0 2rem 0 0;
  color: var(--dark);
}

nav ul li a {
  /* color: #000000; */
  color: inherit;
  font-size: 1.5rem;
  font-weight: 700;
  font-family: var(--subHeadingFont);
  text-decoration: none;
  margin-right: 3rem;
}

nav ul li #nav-contact-button,
#footer-contact-button {
  background-color: var(--accent);
  color: var(--light);
  padding: 0.4rem 0.8rem;
  border-radius: 8px;
}

nav ul li #nav-contact-button,
#footer-contact-button:hover {
  filter: saturate(250%);
  transition: 0.3s;
}

nav a.active {
  opacity: 1;
}

header {
  width: 80%;
  height: 90vh;
  padding: 1rem 1rem 1rem 3rem;
  margin: 13vh 0 0 30vh;
  justify-self: center;
}

header > .header-1 {
  font-size: 4rem;
  font-weight: 700;
  font-family: var(--headingFont);
  color: var(--dark);
}

header > .header-1 span {
  display: inline-block;
}

header > .header-1 .color {
  color: var(--accent);
}
header > .header-2 {
  font-size: 4rem;
  font-weight: 700;
  font-family: var(--headingFont);
  color: var(--accent);
  /* color: #ef8685; */
}

header > .header-p {
  opacity: 0;
  font-size: 2.2rem;
  font-weight: 500;
  font-family: var(--bodyMediumFont);
  color: var(--dark);
}

header .caret-span {
  border-right: 0.05em solid;
  animation: caret 1s steps(1) infinite;
}

@keyframes caret {
  50% {
    border-color: transparent;
  }
}

#portrait {
  padding: 1rem 1rem 1rem 1rem;
  margin: 5vh 0;
  max-width: 100%;
  max-height: 600px;
  min-width: 60%;
  justify-self: center;
  /* filter: drop-shadow(0 0 0.75rem rgba(0, 0, 0, 0.3)); */
  /* filter: hue-rotate(0deg); */
}

.nav-titles {
  /* padding-bottom: 10rem; */
  /* padding-top: 10rem; */
  text-align: center;
  font-size: 0;
  /* margin: 10rem 0; */
}

.nav-titles span {
  display: inline-block;
  font-size: 5rem;
  opacity: 0;
  font-family: var(--headingFont);
  color: var(--dark);
}

.about-me-container {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 5rem;
  background-color: var(--primary);
}

.about-me-container > .about-me-content-container {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
}
.about-me-container > .about-me-content-container > img {
  width: 50vw;
  max-width: 500px;
  height: auto;
  object-fit: cover;
  padding: 2rem 0 2rem 2rem;
  /* transform: scaleX(-1); */
}
.about-me-container > .nav-titles--dark span {
  color: var(--dark);
}

.about-me-container > .about-me-content-container > .about-me-text {
  padding: 2rem 2rem 2rem 0;
  width: 70vw;
  max-width: 1400px;
}

.about-me-container > .about-me-content-container > .about-me-text p {
  font-size: 2rem;
  font-family: var(--bodyFont);
  font-weight: 400;
  /* color: var(--textColor); */
  color: var(--dark);
}

.projects-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-grow: 0;
  gap: 5rem;
  /* padding: 20rem 2rem; */
  /* margin: 10rem auto 20rem auto; */
}
.projects-container > .nav-titles {
  padding-bottom: 5rem;
}

.projects-container .projects-images-container {
  /* padding-top: 20rem; */
}

.contact-me {
  display: flex;
  flex-direction: column;
  height: 100vh;
  gap: 10rem;
  justify-content: center;
}

.contact-me .form-container {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  padding: 1rem 2rem;
  flex-wrap: wrap;
}

.contact-me > .form-container > p {
  font-size: 1.5rem;
  font-family: var(--bodyLightFont);
  /* font-weight: 700; */
  color: var(--dark);
  text-align: center;
  padding-bottom: 1em;
}

.contact-me .form-container p a {
  color: var(--accent);
  text-decoration: none;
}


#email-link {
  position: relative;
}

#email-text {
  font-size: 1.5rem;
}

.tooltip {
  visibility: hidden;
  pointer-events: none;
  width: 110px;
  background-color: var(--light-darker);
  font-family: var(--bodyFont);
  font-size: 1.1rem;

  color: var(--dark--lighter);
  text-align: center;
  padding: 0.4rem 0.1rem;
  border-radius: 5px;

  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  margin-left: -60px;

  opacity: 0;
  transition: opacity 0.3s;
}

.tooltip::after {
  content: '';
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: var(--light-darker) transparent transparent transparent;
}

#email-link:hover .tooltip {
  visibility: visible;
  opacity: 1;
}

.contact-me .form-container form {
  width: 750px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 1rem;
  border: var(--dark) 1px solid;
  box-shadow: var(--accent) 0 0 6px;
  border-radius: 8px;
  background-color: var(--light);
}

.contact-me .form-container form input {
  border-radius: 8px;
  padding: 0.8rem 1rem;
}

.contact-me > .form-container > form > * {
  flex-grow: 1;
  padding: 1rem 1rem;
  margin: 0.4rem;
  border: var(--dark) 1px solid;
  background-color: var(--light);
  filter: brightness(90%);
  color: var(--dark);
  border-radius: 3px;
  font-size: 1.3rem;
  font-family: var(--bodyFont);
  outline: none;
  width: 100%;
}

.contact-me > .form-container > form > input:focus,
.contact-me > .form-container > form > textarea:focus {
  border-color: var(--accent);
}

.contact-me > .form-container  textarea {
  resize: vertical;
  font-size: 1.3rem;
  border-radius: 8px;
  padding: 0.8rem 1rem;
}
.contact-me > .form-container > form > button {
  width: 120px;
  height: 40px;
  font-size: 1.7rem;
  padding: 0;
  flex-grow: 0;
  background-color: var(--accent);
  color: var(--light);
  font-family: var(--headingFont);
  border-radius: 8px;
}

.contact-me > .form-container > form > button:hover {
  filter: saturate(250%);
  transition: 0.4s;
}

footer {
  /* align-self: center; */
  background-color: var(--headerFooterBg);
  margin: 0;
  padding: 0;
}

.footer-nav {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  background-color: var(--headerFooterBg);
  padding: 0 2rem;
  height: 100%;
}

.footer-nav > p {
  font-family: var(--subHeadingFont);
  color: var(--dark);
  font-size: 1.5rem;
  font-weight: 700;
}

.footer-nav ul {
  display: flex;
  justify-content: space-between;
  align-items: center;
  list-style: none;
  flex-direction: row;
  padding: 0;
}

.footer-nav ul li {
  margin-right: 3rem;
}

.footer-nav ul li a {
  color: var(--dark);
  font-size: 1.5rem;
  font-weight: 700;
  text-decoration: none;
  font-family: var(--subHeadingFont);
}

[data-reveal='content'] {
  display: inline-block;
  position: relative;
}

[data-reveal='cover'] {
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 100%;
  z-index: 1;
}

[data-reveal='text'] {
  opacity: 0;
}

@keyframes reveal-cover {
  0% {
    width: 0;
    left: 0;
  }
  44% {
    width: 100%;
    left: 0;
  }
  54% {
    width: 100%;
    left: 0;
  }
  100% {
    width: 0;
    left: 100%;
  }
}

@keyframes reveal-text {
  0% {
    opacity: 0;
  }
  44% {
    opacity: 0;
  }
  54% {
    opacity: 1;
  }
}

[data-js='reveal'].loaded [data-reveal='cover'] {
  animation: reveal-cover 1.5s ease-in-out;
}

[data-js='reveal'].loaded [data-reveal='text'] {
  opacity: 1;
  animation: reveal-text 1.5s ease-in-out;
}

/* Arrows animations */
header > .arrows-container {
  opacity: 0;
  position: relative;
  width: 24px;
  /* height: 24px; */
  bottom: 20%;
  margin: 80% auto;
}

.arrows-container > .chevron {
  position: absolute;
  width: 28px;
  height: 8px;
  opacity: 0;
  transform: scale3d(0.5, 0.5, 0.5);
  animation: move 3s ease-out infinite;
}

.chevron:first-child {
  animation: move 3s ease-out 1s infinite;
}

.chevron:nth-child(2) {
  animation: move 3s ease-out 2s infinite;
}

.chevron:before,
.chevron:after {
  content: ' ';
  position: absolute;
  top: 0;
  height: 100%;
  width: 51%;
  background: var(--accent);
}

.chevron:before {
  left: 0;
  transform: skew(0deg, 30deg);
}

.chevron:after {
  right: 0;
  width: 50%;
  transform: skew(0deg, -30deg);
}

@keyframes move {
  25% {
    opacity: 1;
  }
  33% {
    opacity: 1;
    transform: translateY(30px);
  }
  67% {
    opacity: 1;
    transform: translateY(40px);
  }
  100% {
    opacity: 0;
    transform: translateY(55px) scale3d(0.5, 0.5, 0.5);
  }
}

.arrows-text {
  position: absolute;
  display: block;
  margin-top: 75px;
  margin-left: -30px;
  font-family: 'Helvetica Neue', 'Helvetica', Arial, sans-serif;
  /* font-family: var(--subHeadingFont); */
  font-size: 12px;
  color: var(--accent);
  text-transform: uppercase;
  white-space: nowrap;
  opacity: 0.25;
  animation: pulse 2s linear alternate infinite;
}

@keyframes pulse {
  to {
    opacity: 1;
  }
}

/* Arrows animations end */

@media screen and (max-width: 2000px) {
  header {
    margin-left: 10vh;
  }

  header > .header-1 {
    font-size: 3rem;
  }

  header > .header-2 {
    font-size: 3rem;
  }

  header > .header-p {
    font-size: 2rem;
  }

  .nav-titles span {
    font-size: 4rem;
  }

  .about-me-container > .about-me-content-container > .about-me-text p {
    font-size: 1.8rem;
  }
}

@media screen and (max-width: 1400px) {
  header {
    width: 100%;
    padding: 1rem 1rem 1rem 3rem;
    margin: 13vh 0 0 5vh;
  }

  header > .header-1,
  header > .header-2 {
    font-size: 2.5rem;
  }

  .about-me-container > .about-me-content-container > .about-me-text p {
    font-size: 1.7rem;
  }
}

@media screen and (max-width: 900px) {
  html {
    /* font-size: 62.5%; */
    /* height: -webkit-fill-available; */
  }
  * {
    font-size: 14px;
  }
  body {
    /* height: -webkit-fill-available; */
    /* width: 40%; */
    margin: 0 auto;
    grid-template-columns: 100%;
    /* grid-template-rows: 7vh 93vh minmax(100px, 5fr) 7rem; */
    grid-template-rows:
      7vh minmax(300px, 40vh) minmax(200px, 100vh) minmax(100px, 5fr)
      7rem;

    grid-template-areas:
      'nav'
      'portrait'
      'header'
      'main'
      'footer';
    grid-gap: 0.5rem;
  }

  nav {
    align-items: center;
    justify-content: center;
    width: 90vw;
    margin: 0 auto;
  }

  nav > svg {
    display: none;
  }

  nav ul {
    width: 100%;
    justify-content: space-between;
    margin: 0;
    padding: 0 0.5rem;
  }

  nav ul li a {
    margin: 0;
    font-size: 1.2rem;
    /* justify-items: space-between; */
  }

  nav ul li #nav-contact-button,
  #footer-contact-button {
    padding: 0.2rem 0.6rem;
    border-radius: 4px;
  }

  #portrait {
    margin: 2rem auto;
    max-width: 100%;
    max-height: 300px;
    justify-self: center;
  }

  header {
    margin: 0 auto;
    width: 80%;
    margin: 1rem auto 0 auto;
    height: 100%;
    padding: 1rem 1rem 0 1rem;

    /* justify-self: center; */
    /* justify-content: center; */
    /* text-align: left; */
    /* padding: 0 1rem; */
  }

  /* Adjust other elements as needed. For example, you might want to make 
     images smaller and change the padding or margins on elements. */

  header > .header-1 {
    padding: 0 0 0 1rem;
  }
  header > .header-2 {
    font-size: 2rem;
    padding: 0;
  }

  header > .header-1 span {
    font-size: 1.5rem;
  }

  header > .header-p {
    font-size: 1.2rem;
    padding: 0 1rem;
  }

  header > .arrows-container {
    /* display: none; */
  }


  .nav-titles span {
    font-size: 3rem;
  }
  .about-me-container {
    /* height: 100%; */
    padding: 1rem;
    gap: 3rem;
    height: 100vh;
  }

  .about-me-container > .about-me-content-container > .about-me-text {
    padding: 1rem;
    width: 100%;
  }

  .about-me-container > .about-me-content-container > .about-me-text p {
    font-size: 1.3rem;
    padding: 1rem 0;
  }

  .projects-container {
    padding: 10rem 1rem;
    gap: 3rem;
  }

  .contact-me {
    margin-top: 10rem;
  }

  .contact-me .form-container {
    padding: 0 2rem 2rem 2rem;
  }

  .contact-me > .form-container > p {
    text-align: left;
    font-size: 1.3rem;
  }

  #email-text {
    font-size: 1.3rem;
  }

  .contact-me .form-container form {
    width: 100%;
  }

  .contact-me > .form-container > textarea {
    width: 100% !important;
    resize: vertical;
  }

  .contact-me > .form-container > form > * {
    font-size: 1.2rem;
    padding: 0.7rem 0.5rem;
  }

  .contact-me > .form-container > form > button {
    width: 90px;
    height: 35px;
    font-size: 1.6rem;
    
  }

  [data-js='reveal']:not(.loaded) [data-reveal='text'] {
    opacity: 0;
    animation: hide-text 0.5s ease-in-out;
  }

  @keyframes hide-text {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }

  [data-js='reveal'].loaded [data-reveal='cover'] {
    animation: reveal-cover 1s ease-in-out;
  }

  [data-js='reveal'].loaded [data-reveal='text'] {
    opacity: 1;
    animation: reveal-text 1s ease-in-out;
  }

  footer {
    background-color: var(--headerFooterBg);
    margin: 0;
    padding: 0;
  }

  .footer-nav {
    flex-direction: column;
    padding: 0 0.5rem;
    align-content: center;
    margin: 0 auto;
  }

  .footer-nav > p {
    align-self: baseline;
    padding-left: 1rem;
    font-size: 1.3rem;
  }

  .footer-nav .nav-links {
    width: 100%;
    margin: 0 auto;
    padding: 1rem 1rem;
  }

  .footer-nav ul li {
    margin-right: 0;
    gap: 1rem;
  }

  .footer-nav > .nav-links > li a {
    font-size: 1.2rem;
  }

  header > .arrows-container {
    bottom: 30%;
  }
}
